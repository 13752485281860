<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            cols="12"
            md="3"
            sm="12"
            lg="3"
            ref="menu"
            class="border-right d-flex flex-column flex-nowrap"
            :style="`height: ${height}px;`"
        >
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Users list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="settings.length > 0">
                    <v-list-item-group>
                        <v-list-item
                            v-for="(setting, i) in settings"
                            :key="i"
                            color="primary"
                            three-line
                            @click="hide"
                            link
                            :to="{
                                name:
                                    setting.nameRoute ||
                                    setting.name.toLowerCase(),
                                params: { setting: setting },
                            }"
                            :class="{
                                'border-bottom': i < settings.length - 1,
                            }"
                        >
                            <v-list-item-content>
                                <v-list-item-title class="mt-3">{{
                                    setting.name
                                }}</v-list-item-title>
                                <v-list-item class="pa-0">
                                    <v-list-item-icon class="mt-2 mb-0">
                                        <v-icon>mdi-cog-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-subtitle class="mt-0">
                                        Settings
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </template>
            </v-list>
        </v-col>
        <v-col
            sm="0"
            cols="12"
            md="9"
            lg="9"
            class="overflow-y-auto"
            :style="`height: ${height}px`"
        >
            <router-view @show="show" />
        </v-col>
    </v-row>
</template>

<script>
import API from '@/services/api'
import { storage } from '@/services/firebase'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'Settings',
    data() {
        return {
            loading: false,
            height: 0,
            company: JSON.parse(localStorage.getItem('company')),
            folder: 'company_logo',
            loadingError: false,
            errorMsg: null,
            sortOptions: [
                {
                    text: 'Name',
                    value: 'name',
                },
            ],
        }
    },

    computed: {
        ...mapState(['settings', 'userRef']),
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 40
        },
        ...mapActions(['addSettingsAction']),
        hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
    },
    watch: {
        settings: function(newSet) {
            const params = this.$router.currentRoute.params.setting
            const route = this.$router.currentRoute.name
            if (!params) {
                if (
                    route == 'settingsCosts' ||
                    route == 'settingsCommercial' ||
                    route == 'settingsImage' ||
                    route == 'settingsUnits' ||
                    route == 'emailBodySettings' ||
                    route == 'settingsReasons'
                ) {
                    const setting = newSet.find(i => i.name == 'Quotes')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'settingsCurrencies') {
                    const setting = newSet.find(i => i.name == 'Exchange')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'companyGeneralInfo') {
                    const setting = newSet.find(i => i.name == 'Company')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'rolesSettings') {
                    const setting = newSet.find(i => i.name == 'Permissions')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'operators') {
                    const setting = newSet.find(i => i.name == 'Operators')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'products') {
                    const setting = newSet.find(i => i.name == 'Products')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'invoicingSettings') {
                    const setting = newSet.find(i => i.name == 'Invoicing')
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'specialMaterials') {
                    const setting = newSet.find(
                        i => i.name == 'SpecialMaterials'
                    )
                    this.$router.currentRoute.params.setting = setting
                } else if (route == 'quality') {
                    const setting = newSet.find(i => i.name == 'Quality')
                    this.$router.currentRoute.params.setting = setting
                } else {
                    const setting = newSet.find(i => i.name == 'Calculations')
                    this.$router.currentRoute.params.setting = setting
                }
            }
        },
    },
    async created() {
        try {
            this.loadingError = false
            this.errorMsg = null
            this.loading = true
            const {
                data: { settings },
            } = await API.getSettings()
            const indexImage = settings.findIndex(x => x.name == 'Company')
            if (settings[indexImage].generalInfo.image) {
                const path = `${this.company}/${this.folder}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(
                    settings[indexImage].generalInfo.image.file
                )
                settings[
                    indexImage
                ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
            }
            let orderSettings = settings.filter(x => x.name == 'Company')
            orderSettings[1] = settings.find(x => x.name == 'Exchange')
            orderSettings[2] = settings.find(x => x.name == 'Permissions')
            orderSettings[3] = settings.find(x => x.name == 'Quotes')
            const calculations = this.userRef.permissions.find(
                x => x == 'calculations'
            )

            if (calculations) {
                const item = settings.find(x => x.name == 'Calculations')
                if (item) {
                    orderSettings[4] = item
                }
            }
            const finish = settings.find(x => x.name == 'FinishAndUCCode')
            if (finish) {
                orderSettings.push(finish)
            }
            const processes = settings.find(x => x.name == 'Processes')
            const products = settings.find(x => x.name == 'Products')
            const invoicing = settings.find(x => x.name == 'Invoicing')
            const quality = settings.find(x => x.name == 'Quality')
            if (invoicing) {
                orderSettings.push(invoicing)
            }

            if (processes) {
                const machines = {
                    name: 'Machines',
                    nameRoute: 'machinesSettings',
                    id: processes.id,
                }
                orderSettings.push(machines)
            }

            const workOrderTab = {
                name: 'WorkOrders',
                nameRoute: 'workOrderSettings',
                processes: processes || {},
                products: products || {},
            }
            orderSettings.push(workOrderTab)

            const qualityTab = {
                name: 'Quality',
                nameRoute: 'qualitySettings',
                quality: quality || {},
            }
            orderSettings.push(qualityTab)

            const operatorsTab = {
                name: 'Operators',
                nameRoute: 'operators',
            }
            orderSettings.push(operatorsTab)

            const specialMaterials = settings.find(
                x => x.name == 'SpecialMaterials'
            )
            if (specialMaterials) {
                orderSettings.push(specialMaterials)
            }

            this.addSettingsAction(orderSettings)
        } catch (error) {
            console.log(error.message)
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>
<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
